/* eslint-disable @next/next/no-img-element */

import { useContext } from "react";
import { DARK, ThemeContext } from "../../utils/theme";

export default function Footer() {
  const theme = useContext(ThemeContext);

  return (
    <footer
      className="py-5"
      style={{ backgroundColor: theme?.state === DARK ? "#192b3c" : "#385d81" }}
    >
      <div className="container">
        <p className="m-0 text-center text-white">
          Copyright &copy; komeilmehranfar.com 2021
        </p>
      </div>
    </footer>
  );
}
