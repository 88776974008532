/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-img-element */
/**
 *
 * Settings
 *
 */

import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  analyseBoard,
  defineBox,
  defineCol,
  defineListOfStyleTypesByPriority,
  defineRow,
  generateBoard,
  implementStyles,
  nextCellToBeSolved,
  pushParamsToUrl,
  removeParamsFromUrl,
  solveBoard,
  timerFormatter,
  uniqueid,
  useWindowSize,
} from "../../utils/helper";
import { useSnackbar } from "react-simple-snackbar";

import Header from "../../components/Header";
import Modal from "react-modal";
import { useRouter } from "next/dist/client/router";
import Switch from "react-switch";
import { DARK, ThemeContext } from "../../utils/theme";
import { GEOContext } from "../../utils/geolocation";
const BASE_URL = false
  ? "http://localhost:3000"
  : "https://sudoku.komeilmehranfar.com";
let timerId = null;
const mockBoard = Array(81)
  .fill(0)
  .map(() => ({ value: 0, id: uniqueid() }));

const difficultiesDictionary = {
  easy: "easy",
  medium: "medium",
  hard: "hard",
  expert: "expert",
};

const fetchBoard = async (difficulty) => {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  const res = await fetch(
    `${BASE_URL}/api/sudoku/generate?difficulty=${
      difficultiesDictionary[difficulty] || difficultiesDictionary.easy
    }`,
    {
      mode: "no-cors",
      headers: header,
    }
  );

  const _board = await res.json();
  return _board;
};
const fetchSolvedBoard = async (_board) => {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  const _res = await fetch(`${BASE_URL}/api/sudoku/solve`, {
    method: "POST",
    headers: header,
    body: JSON.stringify(_board),
    mode: "no-cors",
  });
  const solvedBoard = await _res.json();

  return solvedBoard;
};
function GameContainer({
  noRedirect,
  noHeader,
  noPadding,
  difficulty: boardDifficulty,
  board: customBoard,
}) {
  const router = useRouter();
  const theme = useContext(ThemeContext);

  const [isWinner, toggleIsWinner] = useState(false);
  const [autoCheckMistakes, toggleAutoCheckMistakes] = useState(false);
  const [isLoading, toggleLoading] = useState(true);
  const [rawBoard, setRawBoard] = useState(null);
  const [board, setBoard] = useState(null);
  const [solvedBoard, setSolvedBoard] = useState(null);
  const [boardSnaphots, setBoardSnaphots] = useState([]);
  const [isNoteMode, setNoteMode] = useState(false);
  const [paused, togglePause] = useState(false);
  const [timer, setTimer] = useState(null);
  const [isNewGamePopupOpen, toggleNewGamePopup] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [isMounted, toggleMounted] = useState(false);
  const [selectedCell, selectCell] = useState(null);
  const [difficulty, setDifficulty] = useState(
    boardDifficulty || router.query.difficulty || difficultiesDictionary.medium
  );

  useEffect(() => {
    if (isMounted) {
      startNewGame(router.query.difficulty);
    }
  }, [router.query.difficulty]);
  useEffect(() => {
    const localStorageKey = customBoard ? router.query.board : "game";

    const game = localStorage.getItem(localStorageKey)
      ? JSON.parse(localStorage.getItem(localStorageKey))
      : {};

    const {
      board: _board,
      rawBoard: _rawBoard,
      solvedBoard: _solvedBoard,
      selectedCell: _selectedCell,
      isNoteMode: _isNoteMode,
      timer: _timer,
      boardSnaphots: _boardSnaphots,
      difficulty: _difficulty,
      autoCheckMistakes: _autoCheckMistakes,
    } = game;
    if (
      Object.keys(game).length &&
      _board &&
      (_difficulty === difficulty ||
        router.asPath === "/" ||
        router.asPath.search("board") > -1)
    ) {
      setBoard(_board);
      setRawBoard(_rawBoard);
      setSolvedBoard(_solvedBoard);
      selectCell(_selectedCell);
      setNoteMode(_isNoteMode);
      setTimer(_timer);
      setBoardSnaphots(_boardSnaphots);
      setDifficulty(_difficulty);
      toggleLoading(false);
      toggleAutoCheckMistakes(_autoCheckMistakes);
    } else {
      if (customBoard) {
        startNewGameByCustomBoard(customBoard);
      } else {
        startNewGame(difficulty);
      }
    }

    toggleMounted(true);
  }, []);
  const handleKeyboardPressed = (e) => {
    if (selectedCell && board) {
      if (
        ["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(
          e.code
        ) > -1
      ) {
        e.preventDefault();
      }
      switch (e.keyCode) {
        case 37: {
          //left
          if (selectedCell.index !== 0) {
            selectCell(board[selectedCell.index - 1]);
          }
          break;
        }
        case 38: {
          //up
          if (selectedCell.index - 9 >= 0) {
            selectCell(board[selectedCell.index - 9]);
          }
          break;
        }
        case 39: {
          //right
          if (selectedCell.index !== 80) {
            selectCell(board[selectedCell.index + 1]);
          }

          break;
        }
        case 40: {
          //down
          if (selectedCell.index + 9 <= 80) {
            selectCell(board[selectedCell.index + 9]);
          }
          break;
        }
        case 97:
        case 49: {
          handleNumberBtnClick(1);
          // 1
          break;
        }
        case 98:
        case 50: {
          handleNumberBtnClick(2);
          //2
          break;
        }
        case 99:
        case 51: {
          //3
          handleNumberBtnClick(3);
          break;
        }
        case 100:
        case 52: {
          //4
          handleNumberBtnClick(4);
          break;
        }
        case 101:
        case 53: {
          //5
          handleNumberBtnClick(5);
          break;
        }
        case 102:
        case 54: {
          //6
          handleNumberBtnClick(6);
          break;
        }
        case 103:
        case 55: {
          //7
          handleNumberBtnClick(7);
          break;
        }
        case 104:
        case 56: {
          //8
          handleNumberBtnClick(8);
          break;
        }
        case 105:
        case 57: {
          //9
          handleNumberBtnClick(9);
          break;
        }
        case 8: {
          //backspace
          undo();
          break;
        }
        case 68:
        case 46: {
          //delete & D
          erase();
          break;
        }

        case 32:
        case 19: {
          // pause/break & space
          togglePause(!paused);
          break;
        }
        case 83: {
          // S
          toggleAutoCheckMistakes(!autoCheckMistakes);
          break;
        }
        case 72: {
          // H
          hint();
          break;
        }
        case 78: {
          // N
          toggleNewGamePopup(!isNewGamePopupOpen);
          break;
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardPressed);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyboardPressed);
    };
  }, [
    selectedCell,
    board,
    paused,
    autoCheckMistakes,
    isNewGamePopupOpen,
    isNoteMode,
  ]);
  const size = useWindowSize();
  const isMobile = typeof size.width === "undefined" ? true : size.width <= 480;

  useEffect(() => {
    if (timer !== null) {
      if (paused || isLoading) {
        clearInterval(timerId);
      } else {
        let _timer = timer;
        timerId = setInterval(() => {
          _timer += 1;
          setTimer(_timer);
        }, 1000);
      }
    }
  }, [paused, isLoading, timer === null]);

  const handleErrorRenders = (__board) => {
    let _board = JSON.parse(JSON.stringify(__board));

    _board.forEach((cell) => {
      _board[cell.index].has_problem = false;
    });
    [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((_number) => {
      const sameNumberCells = _board.filter((cell) => cell.value === _number);
      sameNumberCells.forEach((cell) => {
        sameNumberCells.forEach((_cell) => {
          if (cell.index !== _cell.index) {
            const column = defineCol(cell.index);
            const row = defineRow(cell.index);
            const box = defineBox(row, column);
            const _column = defineCol(_cell.index);
            const _row = defineRow(_cell.index);
            const _box = defineBox(_row, _column);
            if (_column === column || _row === row || _box === box) {
              _board[cell.index].has_problem = true;
            }
          }
        });
      });
    });
    return _board;
  };
  const handleNumberBtnClick = useCallback(
    (number) => {
      let _board = JSON.parse(JSON.stringify(board));
      if (
        typeof selectedCell?.index === "number" &&
        !board[selectedCell.index].default
      ) {
        if (isNoteMode) {
          _board[selectedCell.index].is_note_mode = true;
          _board[selectedCell.index].candidates[number - 1].is_active =
            !_board[selectedCell.index].candidates[number - 1].is_active;

          const newSnapshots = {
            type: "set_candidate",
            cell_index: selectedCell.index,
            from_note_mode_value: board[selectedCell.index].is_note_mode,
            to_note_mode_value: _board[selectedCell.index].is_note_mode,
            candidate_index: number - 1,
            from_value:
              board[selectedCell.index].candidates[number - 1].is_active,
            to_value:
              board[selectedCell.index].candidates[number - 1].is_active,
          };
          setBoardSnaphots([...boardSnaphots, newSnapshots]);
        } else {
          _board[selectedCell.index].is_note_mode = false;
          if (_board[selectedCell.index].value === number) {
            return erase();
          } else {
            _board[selectedCell.index].value = number;
            _board.forEach((cell) => {
              const column = defineCol(cell.index);
              const row = defineRow(cell.index);
              const box = defineBox(row, column);
              const _column = defineCol(selectedCell.index);
              const _row = defineRow(selectedCell.index);
              const _box = defineBox(_row, _column);
              if (_column === column || _row === row || _box === box) {
                _board[cell.index].candidates[number - 1].is_active = false;
              }
            });
            const newSnapshots = {
              type: "set_value",
              from_note_mode_value: board[selectedCell.index].is_note_mode,
              to_note_mode_value: _board[selectedCell.index].is_note_mode,
              cell_index: selectedCell.index,
              from_value: board[selectedCell.index].value,
              to_value: _board[selectedCell.index].value,
            };
            setBoardSnaphots([...boardSnaphots, newSnapshots]);
          }
          _board[selectedCell.index].default = false;
          if (solvedBoard[selectedCell.index] !== number) {
            _board[selectedCell.index].incorrect = true;
          } else {
            _board[selectedCell.index].incorrect = false;
          }
        }
        _board = handleErrorRenders(_board);
        selectCell(_board[selectedCell.index]);
        setBoard(_board);
      }
    },
    [board, rawBoard, selectedCell?.index, solvedBoard, isNoteMode]
  );
  const undo = () => {
    const _boardSnaphots = JSON.parse(JSON.stringify(boardSnaphots));
    let lastSnapshot = _boardSnaphots.pop();
    if (lastSnapshot?.type) {
      if (lastSnapshot.type === "set_value") {
        let _board = JSON.parse(JSON.stringify(board));
        _board[lastSnapshot.cell_index].value = lastSnapshot.from_value;
        _board[lastSnapshot.cell_index].is_note_mode =
          lastSnapshot.from_note_mode_value;
        _board = handleErrorRenders(_board);
        setBoard(_board);
        setBoardSnaphots(_boardSnaphots);
      } else if (lastSnapshot.type === "set_candidate") {
        let _board = JSON.parse(JSON.stringify(board));
        _board[lastSnapshot.cell_index].is_note_mode =
          lastSnapshot.from_note_mode_value;
        _board[lastSnapshot.cell_index].candidates[
          lastSnapshot.candidate_index
        ].is_active = lastSnapshot.from_value;
        _board = handleErrorRenders(_board);
        setBoard(_board);
        setBoardSnaphots(_boardSnaphots);
      }
    } else if (!lastSnapshot) {
      setBoard(
        rawBoard.map((cell, index) => ({
          value: cell,
          id: uniqueid(),
          default: Boolean(cell),
          has_problem: false,
          incorrect: false,
          index,
          candidates: [1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => ({
            value: number,
            is_active: false,
          })),
          is_note_mode: false,
        }))
      );
    }
  };
  const erase = () => {
    if (selectedCell?.default === false) {
      let _board = JSON.parse(JSON.stringify(board));
      _board[selectedCell.index].value = null;
      _board[selectedCell.index].incorrect = null;
      _board[selectedCell.index].has_problem = null;
      _board[selectedCell.index].candidates = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(
        (number) => ({
          value: number,
          is_active: false,
        })
      );
      _board = handleErrorRenders(_board);
      setBoard(_board);
      const newSnapshots = {
        type: "set_value",
        cell_index: selectedCell.index,
        from_note_mode_value: board[selectedCell.index].is_note_mode,
        to_note_mode_value: _board[selectedCell.index].is_note_mode,
        from_value: board[selectedCell.index].value,
        to_value: _board[selectedCell.index].value,
      };
      setBoardSnaphots([...boardSnaphots, newSnapshots]);
      selectCell(_board[selectedCell.index]);
    }
  };
  const toggleNoteMode = () => {
    setNoteMode(!isNoteMode);
  };
  const hint = async () => {
    let nextCellIndex = await nextCellToBeSolved(
      JSON.parse(JSON.stringify(board.map((cell) => cell.value || undefined)))
    );
    if (nextCellIndex !== -1) {
      selectCell(board[nextCellIndex]);
      openSnackbar("Please pay attention to the selected cell!");
    } else if (!selectedCell) {
      openSnackbar("Please select a cell!");
    } else {
      let _board = JSON.parse(JSON.stringify(board));
      _board[selectedCell.index].value = solvedBoard[selectedCell.index].val;
      _board[selectedCell.index].is_note_mode = false;
      _board = handleErrorRenders(_board);

      setBoard(_board);
      const newSnapshots = {
        type: "set_value",
        from_note_mode_value: board[selectedCell.index].is_note_mode,
        to_note_mode_value: _board[selectedCell.index].is_note_mode,
        cell_index: selectedCell.index,
        from_value: board[selectedCell.index].value,
        to_value: _board[selectedCell.index].value,
      };
      setBoardSnaphots([...boardSnaphots, newSnapshots]);
    }
  };
  const restart = () => {
    resetApp({ solvedBoard: false, rawBoard: false });
    toggleLoading(true);
    togglePause(true);
    setBoard(
      rawBoard.map((cell, index) => ({
        value: cell,
        id: uniqueid(),
        default: Boolean(cell),
        has_problem: false,
        incorrect: false,
        index,
        candidates: [1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => ({
          value: number,
          is_active: false,
        })),
        is_note_mode: false,
      }))
    );
    toggleNewGamePopup(false);
    setTimeout(() => {
      togglePause(false);
      toggleLoading(false);
    }, 300);
  };
  const resetApp = ({ solvedBoard = true, rawBoard = true } = {}) => {
    setTimer(0);
    setBoardSnaphots([]);
    setBoard(null);
    if (solvedBoard) {
      setSolvedBoard(null);
    }
    if (rawBoard) {
      setRawBoard(null);
    }
    toggleIsWinner(false);
    setNoteMode(false);
    selectCell(null);
  };

  const startNewGame = async (difficulty, changeRoute = false) => {
    toggleLoading(true);
    togglePause(true);
    resetApp();
    setTimeout(async () => {
      removeParamsFromUrl();
      if (
        difficulty !== router.query.difficulty &&
        (router.asPath !== "/" || changeRoute) &&
        !noRedirect
      ) {
        return router.push(`/${difficulty}`);
      }
      setDifficulty(difficulty);

      let rawBoard = null;
      if (navigator.onLine) {
        rawBoard = await fetchBoard(difficulty);
      } else {
        rawBoard = await generateBoard(difficulty);
      }
      setRawBoard(rawBoard);
      setBoard(
        rawBoard.map((cell, index) => ({
          value: cell,
          id: uniqueid(),
          default: Boolean(cell),
          has_problem: false,
          incorrect: false,
          index,
          candidates: [1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => ({
            value: number,
            is_active: false,
          })),
          is_note_mode: false,
        }))
      );
      togglePause(false);
      toggleLoading(false);
      let solvedBoard = null;
      if (navigator.onLine) {
        solvedBoard = await fetchSolvedBoard(rawBoard);
      } else {
        solvedBoard = await solveBoard(JSON.parse(JSON.stringify(rawBoard)));
      }
      setSolvedBoard(solvedBoard);
    }, 0);
  };
  const startNewGameByCustomBoard = async (__board) => {
    resetApp();
    const data = await analyseBoard(JSON.parse(JSON.stringify(__board)));
    if (data.finished) {
      setDifficulty(data.level);
      setRawBoard(__board);
      setBoard(
        __board.map((cell, index) => ({
          value: cell,
          id: uniqueid(),
          default: Boolean(cell),
          has_problem: false,
          incorrect: false,
          index,
          candidates: [1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => ({
            value: number,
            is_active: false,
          })),
          is_note_mode: false,
        }))
      );
      togglePause(false);
      toggleLoading(false);
      let solvedBoard = null;
      if (navigator.onLine) {
        solvedBoard = await fetchSolvedBoard(__board);
      } else {
        solvedBoard = await solveBoard(JSON.parse(JSON.stringify(__board)));
      }
      setSolvedBoard(solvedBoard);
    }
  };
  const checkIsWinner = () => {
    if (board?.every((cell) => cell.value === solvedBoard?.[cell.index])) {
      setTimeout(() => {
        pushParamsToUrl({ is_finished: true, time: timer });
        togglePause(true);
        toggleIsWinner(true);
      }, 400);
    }
  };
  const checkIfNumberIsUsedCompletely = (number) => {
    return board?.filter((cell) => cell.value === number).length === 9;
  };
  const saveTheGame = () => {
    const localStorageKey = customBoard ? router.query.board : "game";
    if (board) {
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          board,
          rawBoard,
          solvedBoard,
          selectedCell,
          isNoteMode,
          timer,
          boardSnaphots,
          difficulty,
          autoCheckMistakes,
          isCustomBoard: Boolean(customBoard),
        })
      );
    }
  };
  useEffect(() => {
    checkIsWinner();
    saveTheGame();
  }, [
    JSON.stringify(board),
    JSON.stringify(rawBoard),
    JSON.stringify(solvedBoard),
    JSON.stringify(selectedCell),
    isNoteMode,
    timer,
    JSON.stringify(boardSnaphots),
    difficulty,
    autoCheckMistakes,
  ]);
  useEffect(() => {
    checkIsWinner();
  }, [JSON.stringify(board), JSON.stringify(solvedBoard)]);

  return (
    <div>
      {!noHeader && (
        <Header
          isMobile={isMobile}
          toggleNewGamePopup={() => toggleNewGamePopup(!isNewGamePopupOpen)}
        />
      )}
      <Modal
        isOpen={isNewGamePopupOpen}
        onRequestClose={() => toggleNewGamePopup(false)}
        style={{
          content: {
            maxWidth: "480px",
            margin: "auto",
            height: "min-content",
            backgroundColor: theme?.state === DARK ? "#192b3c" : "#ffffff",
            border: theme?.state === DARK ? "#192b3c" : "#ffffff",
          },
          overlay: {
            backgroundColor: theme?.state === DARK ? "#000000a6" : "#ffffff9c",
          },
        }}
      >
        <div
          className="new-game-item p-3"
          onClick={() => {
            startNewGame(difficultiesDictionary.easy, true);
            toggleNewGamePopup(false);
          }}
        >
          Easy
        </div>
        <div
          className="new-game-item p-3"
          onClick={() => {
            startNewGame(difficultiesDictionary.medium, true);
            toggleNewGamePopup(false);
          }}
        >
          Medium
        </div>
        <div
          className="new-game-item p-3"
          onClick={() => {
            startNewGame(difficultiesDictionary.hard, true);

            toggleNewGamePopup(false);
          }}
        >
          Hard
        </div>
        <div
          className="new-game-item p-3"
          onClick={() => {
            startNewGame(difficultiesDictionary.expert, true);

            toggleNewGamePopup(false);
          }}
        >
          Expert
        </div>
        <hr />
        <div className="new-game-item p-3" onClick={restart}>
          Restart
        </div>
      </Modal>

      <div className="mt-3 container" style={{ padding: noPadding ? 0 : "" }}>
        <div className="d-flex justify-content-between align-items-center col-12 col-lg-7 px-0">
          <div
            style={{
              color: theme?.state === DARK ? "white" : "#344861",
              fontWeight: 700,
            }}
          >
            <span className="d-none d-sm-inline-block mr-1">Difficulty: </span>
            <select
              style={{
                color: theme?.state === DARK ? "white" : "#748091",
                cursor: "pointer",
              }}
              value={difficulty || difficultiesDictionary.easy}
              onChange={(e) => {
                startNewGame(e.target.value, true);
              }}
            >
              <option value={difficultiesDictionary.easy}>Easy</option>
              <option value={difficultiesDictionary.medium}>Medium</option>
              <option value={difficultiesDictionary.hard}>Hard</option>
              <option value={difficultiesDictionary.expert}>Expert</option>
            </select>
          </div>
          <div>
            <label
              htmlFor="material-switch"
              className="m-0 d-flex align-items-center"
            >
              <span
                className="mr-2 d-sm-none"
                style={{ color: theme?.state === DARK ? "white" : "#748091" }}
              >
                Mistakes
              </span>
              <span
                className="d-none d-sm-inline-block mr-2"
                style={{ color: theme?.state === DARK ? "white" : "#748091" }}
              >
                Auto-Check for Mistakes
              </span>
              <Switch
                checked={autoCheckMistakes}
                onChange={toggleAutoCheckMistakes}
                onColor="#91c1f1"
                onHandleColor="#385d81"
                handleDiameter={25}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 0px 4px rgba(0, 0, 0, 0.4)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={16}
                width={40}
                className="react-switch"
                id="material-switch"
              />
            </label>
          </div>
          <div
            style={{
              width: 100,
              color: theme?.state === DARK ? "white" : "#748091",
              cursor: "pointer",
            }}
            className="d-flex align-items-center justify-content-end"
            onClick={() => (isWinner ? null : togglePause(!paused))}
          >
            <div>{timerFormatter(timer)} </div>
            <div>
              <button name="timer-pause" className="timer-pause">
                {!paused ? (
                  <svg
                    className="icon icon-pause"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 11 11"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path fill="none" d="M0 0h11v11H0z"></path>
                      <path
                        fill={theme?.state === DARK ? "white" : "#748091"}
                        d="M3.5 0a1 1 0 011 1v9a1 1 0 01-2 0V1a1 1 0 011-1zm4 0a1 1 0 011 1v9a1 1 0 01-2 0V1a1 1 0 011-1z"
                      ></path>
                    </g>
                  </svg>
                ) : (
                  <svg
                    className="icon icon-play"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 11 11"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path fill="none" d="M0 0h11v11H0z"></path>
                      <path
                        fill={theme?.state === DARK ? "white" : "#748091"}
                        d="M9.3 6.32l-5.23 3.6A1 1 0 012.5 9.1V1.9a1 1 0 011.57-.82l5.23 3.6a1 1 0 010 1.64z"
                      ></path>
                    </g>
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div
          className="container d-flex flex-wrap justify-conteent-between"
          style={{ padding: noPadding ? 0 : "" }}
        >
          <div
            style={{
              background: theme?.state === DARK ? "#344861" : "#bfc6d4",
              position: "relative",
            }}
            className="w-100 col-12 col-sm-5 col-md-7 p-0"
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(9, 1fr)",
                gridTemplateRows: "repeat(9, 1fr)",
                gridGap: "1px",
              }}
              className="sudoku-board-grid"
            >
              {(isLoading || !board ? mockBoard : board)?.map((cell) => {
                const styles =
                  paused || cell.value === 0
                    ? {}
                    : implementStyles(
                        defineListOfStyleTypesByPriority(
                          cell,
                          selectedCell,
                          autoCheckMistakes
                        ),
                        theme?.state
                      );

                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      background: theme?.state === DARK ? "#192b3c" : "white",
                      fontWeight: 300,
                      color: cell.value
                        ? theme?.state === DARK
                          ? "white"
                          : "#344861"
                        : "transparent",
                      cursor: "default",
                      ...styles,
                    }}
                    key={cell.id}
                    onClick={() => selectCell(cell)}
                    className="aspect-ratio-box font-size-24-19-30-44"
                  >
                    {!cell.is_note_mode ? (
                      <div
                        style={{
                          opacity: paused ? 0 : 1,
                        }}
                      >
                        {cell.value}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 3fr)",
                          gridTemplateRows: "repeat(3, 3fr)",
                          gridGap: "1px",
                          width: "100%",
                          opacity: paused ? 0 : 1,
                        }}
                      >
                        {cell.candidates?.map((candidate) => (
                          <div
                            style={{
                              fontSize: isMobile ? 12 : 18,
                              lineHeight: isMobile ? "10px" : "14px",
                              fontWeight:
                                candidate.value === selectedCell.value
                                  ? 700
                                  : 400,
                              color: candidate.is_active
                                ? candidate.value === selectedCell.value
                                  ? "#344861"
                                  : "#717f8e"
                                : "transparent",
                            }}
                            className="aspect-ratio-box d-flex align-items-center justify-content-center"
                            key={candidate.value}
                          >
                            {candidate.value}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              style={{
                position: "absolute",
                top: isMobile ? "33.23%" : "33.33%",
                left: 0,
                width: "100%",
                height: isMobile ? 1 : 2,
                background: theme?.state === DARK ? "#375d81" : "#344861",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                top: "66.45%",
                left: 0,
                width: "100%",
                height: isMobile ? 1 : 2,
                background: theme?.state === DARK ? "#375d81" : "#344861",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: isMobile ? "33.23%" : "33.33%",
                width: isMobile ? 1 : 2,
                height: "100%",
                background: theme?.state === DARK ? "#375d81" : "#344861",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: "66.45%",
                width: isMobile ? 1 : 2,
                height: "100%",
                background: theme?.state === DARK ? "#375d81" : "#344861",
              }}
            ></div>
            <div
              className="board-overlay"
              style={{
                opacity: !isLoading && paused ? 1 : 0,
                pointerEvents: !isLoading && paused ? "auto" : "none",
                transition: "all 0.3s ease-in-out",
              }}
              onClick={() => togglePause(false)}
            >
              <img
                src="/pause.svg"
                alt="Sudoku pause icon"
                className="icon-play-big"
              />
            </div>
            <div
              className="board-overlay h-100"
              style={{
                background: "white",
                opacity: !isLoading && isWinner ? 1 : 0,
                pointerEvents: !isLoading && isWinner ? "auto" : "none",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <img
                src="/confetti.gif"
                alt="Sudoku confetti"
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
              />
              <div
                style={{ top: 0, left: 0, background: "#385d8182" }}
                className="position-absolute flex-column d-flex align-items-center justify-content-center w-100 h-100"
              >
                <div
                  className="my-2"
                  style={{ fontSize: 40, fontWeight: 700, color: "white" }}
                >
                  Excellent!
                </div>
                <div
                  className="my-2"
                  style={{ fontSize: 16, fontWeight: 700, color: "white" }}
                >
                  Time: {timerFormatter(timer)}
                </div>
                <button
                  name="new-game"
                  className="btn-primary-2"
                  style={{ maxWidth: "50%" }}
                  onClick={() => toggleNewGamePopup(!isNewGamePopupOpen)}
                >
                  New Game
                </button>
              </div>
            </div>

            <div
              className="board-overlay"
              style={{
                background: "white",
                opacity: isLoading ? 1 : 0,
                pointerEvents: isLoading ? "auto" : "none",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <div
                style={{
                  top: 0,
                  left: 0,
                  background: theme?.state === DARK ? "#192b3c" : "#385d8182",
                }}
                className="position-absolute flex-column d-flex align-items-center justify-content-center w-100 h-100 p-4"
              >
                <div className="lds-dual-ring"></div>
                <div
                  style={{
                    color: "white",
                    marginTop: "20px",
                    fontSize: "20px",
                  }}
                  className="text-center"
                >
                  Please wait.
                  <div>Sudoku Puzzle is being generated...</div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className="col-12 col-sm-7 col-md-5 px-0 pl-sm-3  pl-landscape-3"
          >
            <div>
              <button
                name="new-game"
                className="btn-primary-1 d-none d-sm-block"
                onClick={() => toggleNewGamePopup(!isNewGamePopupOpen)}
              >
                New Game
              </button>
            </div>

            <div className="mb-sm-1 mb-lg-4 mb-0 action-btns-container">
              <div className="action-btn-container">
                <button
                  name="undo"
                  className="action-btn"
                  onClick={undo}
                  disabled={isWinner}
                >
                  <img
                    src={
                      theme?.state === DARK ? "/undoz-night.svg" : "/undoz.svg"
                    }
                    alt="Sudoku undo icon"
                  />
                </button>
              </div>
              <div className="action-btn-container">
                <button
                  name="erase"
                  className="action-btn"
                  onClick={erase}
                  disabled={isWinner}
                >
                  <img
                    src={
                      theme?.state === DARK ? "/erase-night.svg" : "/erase.svg"
                    }
                    alt="Sudoku erase icon"
                  />
                </button>
              </div>
              <div className="action-btn-container">
                <button
                  name="note"
                  className={`action-btn position-relative ${
                    isNoteMode ? "note-mode" : ""
                  }`}
                  onClick={toggleNoteMode}
                  disabled={isWinner}
                >
                  <div className="px-2 badge">{isNoteMode ? "ON" : "OFF"}</div>
                  <img
                    src={
                      theme?.state === DARK ? "/notez-night.svg" : "/notez.svg"
                    }
                    alt="Sudoku note icon"
                  />
                </button>
              </div>
              <div className="action-btn-container">
                <button
                  name="hint"
                  className="action-btn"
                  onClick={hint}
                  disabled={isWinner}
                >
                  <img
                    src={
                      theme?.state === DARK ? "/hintz-night.svg" : "/hintz.svg"
                    }
                    alt="Sudoku hint icon"
                  />
                </button>
              </div>
            </div>
            <div className="number-btns-container mt-2 w-100">
              <button
                name="1"
                onClick={() => handleNumberBtnClick(1)}
                className="number-btn"
                style={{ opacity: checkIfNumberIsUsedCompletely(1) ? 0.2 : 1 }}
                disabled={isWinner || checkIfNumberIsUsedCompletely(1)}
              >
                1
              </button>
              <button
                name="2"
                onClick={() => handleNumberBtnClick(2)}
                className="number-btn"
                style={{ opacity: checkIfNumberIsUsedCompletely(2) ? 0.2 : 1 }}
                disabled={isWinner || checkIfNumberIsUsedCompletely(2)}
              >
                2
              </button>
              <button
                name="3"
                onClick={() => handleNumberBtnClick(3)}
                className="number-btn"
                style={{ opacity: checkIfNumberIsUsedCompletely(3) ? 0.2 : 1 }}
                disabled={isWinner || checkIfNumberIsUsedCompletely(3)}
              >
                3
              </button>
              <button
                name="4"
                onClick={() => handleNumberBtnClick(4)}
                className="number-btn"
                style={{ opacity: checkIfNumberIsUsedCompletely(4) ? 0.2 : 1 }}
                disabled={isWinner || checkIfNumberIsUsedCompletely(4)}
              >
                4
              </button>
              <button
                name="5"
                onClick={() => handleNumberBtnClick(5)}
                className="number-btn"
                style={{ opacity: checkIfNumberIsUsedCompletely(5) ? 0.2 : 1 }}
                disabled={isWinner || checkIfNumberIsUsedCompletely(5)}
              >
                5
              </button>
              <button
                name="6"
                onClick={() => handleNumberBtnClick(6)}
                className="number-btn"
                style={{ opacity: checkIfNumberIsUsedCompletely(6) ? 0.2 : 1 }}
                disabled={isWinner || checkIfNumberIsUsedCompletely(6)}
              >
                6
              </button>
              <button
                name="7"
                onClick={() => handleNumberBtnClick(7)}
                className="number-btn"
                style={{ opacity: checkIfNumberIsUsedCompletely(7) ? 0.2 : 1 }}
                disabled={isWinner || checkIfNumberIsUsedCompletely(7)}
              >
                7
              </button>
              <button
                name="8"
                onClick={() => handleNumberBtnClick(8)}
                className="number-btn"
                style={{ opacity: checkIfNumberIsUsedCompletely(8) ? 0.2 : 1 }}
                disabled={isWinner || checkIfNumberIsUsedCompletely(8)}
              >
                8
              </button>
              <button
                name="9"
                onClick={() => handleNumberBtnClick(9)}
                className="number-btn"
                style={{ opacity: checkIfNumberIsUsedCompletely(9) ? 0.2 : 1 }}
                disabled={isWinner || checkIfNumberIsUsedCompletely(9)}
              >
                9
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(GameContainer);
